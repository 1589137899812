/* PAGINATION STARTS */
.pagination{
  display: flex;
  justify-content: center;
  padding: 0;
  gap: 1rem;
}

.pagination-item{
  list-style: none;  
  width: 4rem;
  height: 4rem;
  padding: 1rem 1.5rem;
  cursor: pointer;
  transition: all 0.3s;
  font-size: 1.6rem;
  color: #111D5E;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination-link{
  text-decoration: none;
  font-size: 1.6rem;
  color: #111D5E;
  transition: all 0.3s;
  font-weight: 600;
}

.pagination-item:hover{  
  background: #e32845;
  font-weight: 700;  
  color: white;
}

.pagination-item-active{
  background-color: #e32845;  
}

.pagination-link-active{
  color: white !important;
  font-weight: 700;
}

/* PAGINATION ENDS */

@media screen and (max-width: 767px) {  
  .pagination{
    flex-wrap: wrap;
  }
}

.social-icon:hover {
  opacity: 100% !important;
  color: white !important;
}

.text-justify{  
  text-align: justify;
}