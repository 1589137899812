html,
body {
  padding: 0;
  margin: 0;  
  font-family: "Nunito Sans", sans-serif !important;
  max-width: 192rem;
  margin-left: auto;
  margin-right: auto;  
}
html {
  /* Set 1rem to the browser default (10px) */
  font-size: 62.5%;
  overflow-x: hidden;
}
a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  font-family: "Nunito Sans", sans-serif !important;
  letter-spacing: 0.08rem;
  padding: 0;
  margin: 0;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* form input css */
input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
input[type="date"],
input[type="time"],
input[type="search"],
textarea {
  display: block;
  width: 100%;
  padding: 0.5rem 1.5rem;
  font-size: 1.6rem;
  line-height: 1.8rem;  
  font-weight: 500;
  border-radius: 0.4rem;  
  resize: vertical;
  outline: none;
  color: black;  
  background-color: #f3f3f3;
  border: 1px solid transparent;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
input[type="date"], 
input[type="search"],
input[type="time"]{
  height: 5.5rem;
}

/* SAFARI FIX */
input[type="date"]{
  min-width:95% !important;  
}

.form-input-select{  
	height: 4rem;
  padding: 0.5rem 1rem;  
  border: 1px solid #DEE1E6;
  border-radius: 0.4rem;
  outline: none;
  font-size: 1.4rem;
  line-height: 1.8rem;
  min-width: 16rem;
}

::-webkit-input-placeholder {
  color: #a5aaad;
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 1.8rem;  
}

.error {
  color: red;
  position: absolute;
  right: 0;
  font-size: 1.2rem;  
}

.input-error {
  border: 1px solid;
  border-color: red !important;
}

.active{
  color: #fd7e14 !important;
  font-weight: 600 !important;
}

::-webkit-scrollbar{
  width: 5px;
  height: 6px;
}

::-webkit-scrollbar-track{
  box-shadow: inset 0 0 5px #a5aaad;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb{
  background: #111d5e;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover{
  background: #a5aaad;
}

.break-word{
  word-break: break-word;
}